// Auth action type
export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED';
export const USER_DATA_INIT = 'USER_DATA_INIT';
export const AUTH_ERROR_MSG = 'AUTH_ERROR_MSG';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const AUTH_INIT = 'AUTH_INIT';
export const CLEAR_USER_AUTH = 'CLEAR_USER_AUTH';
export const GET_EMPRESA_INIT = 'GET_EMPRESA_INIT';
export const GET_EMPRESA_SUCCESS = 'GET_EMPRESA_SUCCESS';
export const SET_EMPRESA = 'SET_EMPRESA';
// -------------------

// Users action Type
export const USERS_FETCH_DATA_INIT = 'USERS_FETCH_DATA_INIT';
export const USERS_FETCH_DATA_SUCCESS = 'USERS_FETCH_DATA_SUCCESS';

export const USERS_DELETE_USER_INIT = 'USERS_DELETE_USER_INIT';
export const USERS_DELETE_USER_SUCCESS = 'USERS_DELETE_USER_SUCCESS';

export const USERS_CREATE_USER_INIT = 'USERS_CREATE_USER_INIT';
export const USERS_CREATE_USER_SUCCESS = 'USERS_CREATE_USER_SUCCESS';

export const USERS_MODIFY_USER_INIT = 'USERS_MODIFY_USER_INIT';
export const USERS_MODIFY_USER_SUCCESS = 'USERS_MODIFY_USER_SUCCESS';

export const USERS_CLEAN_UP = 'USERS_CLEAN_UP';
export const USERS_CLEAR_DATA_LOGOUT = 'USERS_CLEAR_DATA_LOGOUT';
export const ATRIBUIR_EMPRESA_INIT = 'ATRIBUIR_EMPRESA_INIT';
export const ATRIBUIR_EMPRESA_SUCCESS = 'ATRIBUIR_EMPRESA_SUCCESS';

export const USERS_ERROR_MSG = 'USERS_ERROR_MSG';
// ---------------------------------------------------------------

// Admin action types
export const ADMIN_ERROR_MSG = 'ADMIN_ERROR_MSG';
export const CREATE_TIPOS_DASH_SUCCESS = 'CREATE_TIPOS_DASH_SUCCESS';
export const CREATE_TIPOS_DASH_INIT = 'CREATE_TIPOS_DASH_INIT';
export const CREATE_DASHBOARD_INIT = 'CREATE_DASHBOARD_INIT';
export const DELETE_DASHBOARD_INIT = 'DELETE_DASHBOARD_INIT';
export const UPDATE_DASHBOARD_INIT = 'UPDATE_DASHBOARD_INIT';
export const CREATE_DASHBOARD_SUCCESS = 'CREATE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS';
export const ADMIN_CLEAR_ALL = 'ADMIN_CLEAR_ALL';
export const GET_DASHBOARDS_INIT = 'GET_DASHBOARDS_INIT';
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS';

// ---------------------------------------------------------------------

// Empresas action types
export const DELETE_EMPRESA_INIT = 'DELETE_EMPRESA_INIT';
export const DELETE_EMPRESA_SUCCESS = 'DELETE_EMPRESA_SUCCESS';
export const LIST_EMPRESA_INIT = 'LIST_EMPRESA_INIT';
export const LIST_EMPRESA_SUCCESS = 'LIST_EMPRESA_SUCCESS';
export const MODIFY_EMPRESA_INIT = 'MODIFY_EMPRESA_INIT';
export const MODIFY_EMPRESA_SUCCESS = 'MODIFY_EMPRESA_SUCCESS';
export const CREATE_EMPRESA_INIT = 'CREATE_EMPRESA_INIT';
export const CREATE_EMPRESA_SUCCESS = 'CREATE_EMPRESA_SUCCESS';
export const EMPRESA_ERROR_MSG = 'EMPRESA_ERROR_MSG';
export const EMPRESA_CLEAN_UP = 'EMPRESA_CLEAN_UP';
export const EMPRESA_CLEAN_LIST_EMPS = 'EMPRESA_CLEAN_LIST_EMPS';

// ---------------------------------------------------------------------

// Dashboards action types
export const LIST_TIPOS_DASH_INIT = 'LIST_TIPOS_DASH_INIT';
export const LIST_TIPOS_DASH_SUCCESS = 'LIST_TIPOS_DASH_SUCCESS';
export const LIST_MY_DASHS_INIT = 'LIST_MY_DASHS_INIT';
export const LIST_MY_DASHS_SUCCESS = 'LIST_MY_DASHS_SUCCESS';
export const DASH_ERROR_MSG = 'DASH_ERROR_MSG';
export const CLEAR_DASH_STATUS = 'CLEAR_DASH_STATUS';
export const SET_DASHBOARDS_USER_INIT = 'SET_DASHBOARDS_USER_INIT';
export const SET_DASHBOARDS_USER_SUCCESS = 'SET_DASHBOARDS_USER_SUCCESS';


// Logs -------------------------------------------------------------------
export const SEND_LOG = 'SEND_LOG';
export const LOG_ERROR_MSG = 'LOG_ERROR_MSG';

// Metas -------------------------------------------------------------------
export const CREATE_METAS_INIT = 'CREATE_METAS_INIT';
export const CREATE_METAS_SUCCESS = 'CREATE_METAS_SUCCESS';
export const METAS_ERROR_MSG = 'METAS_ERROR_MSG';
export const CLEAR_METAS = 'CLEAR_METAS';
export const UPDATE_META_INIT = 'UPDATE_META_INIT';
export const UPDATE_META_SUCCESS = 'UPDATE_META_SUCCESS';;
export const META_LIST_METAS_INIT = 'META_LIST_METAS_INIT';
export const META_LIST_METAS_SUCCESS = 'META_LIST_METAS_SUCCESS';
export const META_DELETE_META_INIT = 'META_DELETE_META_INIT';
export const META_DELETE_META_SUCCESS = 'META_DELETE_META_SUCCESS';
export const META_OBTEM_META_INIT = 'META_OBTEM_META_INIT';
export const META_OBTEM_META_SUCCESS = 'META_OBTEM_META_SUCCESS';

// Colaboradores -------------------------------------------------------------------
export const LIST_COLABORADOR_INIT = 'LIST_COLABORADOR_INIT';
export const LIST_COLABORADOR_SUCCESS = 'LIST_COLABORADOR_SUCCESS';
export const CREATE_COLABORADOR_INIT = 'CREATE_COLABORADOR_INIT';
export const CREATE_COLABORADOR_SUCCESS = 'CREATE_COLABORADOR_SUCCESS';
export const EDIT_COLABORADOR_INIT = 'EDIT_COLABORADOR_INIT';
export const EDIT_COLABORADOR_SUCCESS = 'EDIT_COLABORADOR_SUCCESS';
export const CLEAR_COLABORADORES = 'CLEAR_COLABORADORES';
export const COLABORADORES_ERRO_MSG = 'COLABORADORES_ERRO_MSG';

// Grupo de controle--------------------------------------------------------------------
export const LIST_GRUPO_CONTROLE_INIT = 'LIST_GRUPO_CONTROLE_INIT';
export const LIST_GRUPO_CONTROLE_SUCCESS = 'LIST_GRUPO_CONTROLE_SUCCESS';
export const CREATE_GRUPO_CONTROLE_INIT = 'CREATE_GRUPO_CONTROLE_INIT';
export const CREATE_GRUPO_CONTROLE_SUCCESS = 'CREATE_GRUPO_CONTROLE_SUCCESS';
export const UPDATE_GRUPO_CONTROLE_INIT = 'UPDATE_GRUPO_CONTROLE_INIT';
export const UPDATE_GRUPO_CONTROLE_SUCCESS = 'UPDATE_GRUPO_CONTROLE_SUCCESS';
export const DELETE_GRUPO_CONTROLE_INIT = 'DELETE_GRUPO_CONTROLE_INIT';
export const DELETE_GRUPO_CONTROLE_SUCCESS = 'DELETE_GRUPO_CONTROLE_SUCCESS';
export const CLEAR_GRUPO_CONTROLE = 'CLEAR_GRUPO_CONTROLE';
export const ERROR_GRUPO_CONTROLE_MSG = 'ERROR_GRUPO_CONTROLE_MSG';

// Fator --------------------------------------------------------------------
export const CREATE_FATOR_INIT = 'CREATE_FATOR_INIT';
export const CREATE_FATOR_SUCCESS = 'CREATE_FATOR_SUCCESS';
export const FATOR_ERROR_MSG = 'FATOR_ERROR_MSG';
export const CLEAR_FATOR = 'CLEAR_FATOR';
export const UPDATE_FATOR_INIT = 'UPDATE_FATOR_INIT';
export const UPDATE_FATOR_SUCCESS = 'UPDATE_FATOR_SUCCESS';
export const FATOR_LIST_FATORES_EMPRESA_INIT = 'FATOR_LIST_FATORES_EMPRESA_INIT';
export const FATOR_LIST_FATORES_EMPRESA_SUCCESS = 'FATOR_LIST_FATORES_EMPRESA_SUCCESS';
export const FATOR_OBTEM_FATOR_INIT = 'FATOR_OBTEM_FATOR_INIT';
export const FATOR_OBTEM_FATOR_SUCCESS = 'FATOR_OBTEM_FATOR_SUCCESS';


// Campanha ----------------------------------------------------------------
export const CREATE_CAMPANHA_INIT = 'CREATE_CAMPANHA_INIT';
export const CREATE_CAMPANHA_SUCCESS = 'CREATE_CAMPANHA_SUCCESS';
export const CAMPANHA_ERROR_MSG = 'CAMPANHA_ERROR_MSG';
export const CLEAR_CAMPANHA = 'CLEAR_CAMPANHA';
export const UPDATE_CAMPANHA_INIT = 'UPDATE_CAMPANHA_INIT';
export const UPDATE_CAMPANHA_SUCCESS = 'UPDATE_CAMPANHA_SUCCESS';
export const DUPLICATE_CAMPANHA_INIT = 'DUPLICATE_CAMPANHA_INIT';
export const DUPLICATE_CAMPANHA_SUCCESS = 'DUPLICATE_CAMPANHA_SUCCESS';
export const CAMPANHA_LIST_CAMPANHA_INIT = 'CAMPANHA_LIST_CAMPANHA_INIT';
export const CAMPANHA_LIST_CAMPANHA_SUCCESS = 'CAMPANHA_LIST_CAMPANHA_SUCCESS';
export const CAMPANHA_DELETE_CAMPANHA_INIT = 'CAMPANHA_DELETE_CAMPANHA_INIT';
export const CAMPANHA_DELETE_CAMPANHA_SUCCESS = 'CAMPANHA_DELETE_CAMPANHA_SUCCESS';
export const CAMPANHA_OBTEM_CAMPANHA_INIT = 'CAMPANHA_OBTEM_CAMPANHA_INIT';
export const CAMPANHA_OBTEM_CAMPANHA_SUCCESS = 'CAMPANHA_OBTEM_CAMPANHA_SUCCESS';

// Excesso Estoque ----------------------------------------------------------------
export const CLEAR_FLAGS_ESTOQUE = 'CLEAR_FLAGS_ESTOQUE';
export const SET_ESTOQUE_DADOS_INIT = 'SET_ESTOQUE_DADOS_INIT';
export const SET_ESTOQUE_DADOS_SUCCESS = 'SET_ESTOQUE_DADOS_SUCCESS';
export const ERROR_ESTOQUE_MSG = 'ERROR_ESTOQUE_MSG';

// Curva_ABC ----------------------------------------------------------------
export const CLEAR_FLAGS_CURVA = 'CLEAR_FLAGS_CURVA';
export const SET_CURVA_ABC_INIT = 'SET_CURVA_ABC_INIT';
export const SET_CURVA_ABC_SUCCESS = 'SET_CURVA_ABC_SUCCESS';
export const ERROR_CURVA_MSG = 'ERROR_CURVA_MSG';

// Categorias action types -----------------------------------------------------------
export const LIST_CATEGORIA_INIT = 'LIST_CATEGORIA_INIT';
export const LIST_CATEGORIA_SUCCESS = 'LIST_CATEGORIA_SUCCESS';
export const CREATE_CATEGORIA_INIT = 'CREATE_CATEGORIA_PAINEL_INIT';
export const CREATE_CATEGORIA_SUCCESS = 'CREATE_CATEGORIA_SUCCESS';
export const UPDATE_CATEGORIA_INIT = 'UPDATE_CATEGORIA_INIT';
export const UPDATE_CATEGORIA_SUCCESS = 'UPDATE_CATEGORIA_SUCCESS';
export const DELETE_CATEGORIA_INIT = 'DELETE_CATEGORIA_INIT';
export const DELETE_CATEGORIA_SUCCESS = 'DELETE_CATEGORIA_SUCCESS';
export const CATEGORIA_ERROR_MSG = 'CATEGORIA_ERROR_MSG';
export const CLEAR_CATEGORIA = 'CLEAR_CATEGORIA';

// Classificacoes action types -----------------------------------------------------------
export const LIST_CLASSIFICACAO_INIT = 'LIST_CLASSIFICACAO_INIT';
export const LIST_CLASSIFICACAO_SUCCESS = 'LIST_CLASSIFICACAO_SUCCESS';
export const CREATE_CLASSIFICACAO_INIT = 'CREATE_CLASSIFICACAO_INIT';
export const CREATE_CLASSIFICACAO_SUCCESS = 'CREATE_CLASSIFICACAO_SUCCESS';
export const UPDATE_CLASSIFICACAO_INIT = 'UPDATE_CLASSIFICACAO_INIT';
export const UPDATE_CLASSIFICACAO_SUCCESS = 'UPDATE_CLASSIFICACAO_SUCCESS';
export const DELETE_CLASSIFICACAO_INIT = 'DELETE_CLASSIFICACAO_INIT';
export const DELETE_CLASSIFICACAO_SUCCESS = 'DELETE_CLASSIFICACAO_SUCCESS';
export const CLASSIFICACAO_ERROR_MSG = 'CLASSIFICACAO_ERROR_MSG';
export const CLEAR_CLASSIFICACAO = 'CLEAR_CLASSIFICACAO';

// Motivos action types -----------------------------------------------------------
export const LIST_MOTIVO_INIT = 'LIST_MOTIVO_INIT';
export const LIST_MOTIVO_SUCCESS = 'LIST_MOTIVO_SUCCESS';
export const CREATE_MOTIVO_INIT = 'CREATE_MOTIVO_PAINEL_INIT';
export const CREATE_MOTIVO_SUCCESS = 'CREATE_MOTIVO_SUCCESS';
export const UPDATE_MOTIVO_INIT = 'UPDATE_MOTIVO_INIT';
export const UPDATE_MOTIVO_SUCCESS = 'UPDATE_MOTIVO_SUCCESS';
export const DELETE_MOTIVO_INIT = 'DELETE_MOTIVO_INIT';
export const DELETE_MOTIVO_SUCCESS = 'DELETE_MOTIVO_SUCCESS';
export const MOTIVO_ERROR_MSG = 'MOTIVO_ERROR_MSG';
export const CLEAR_MOTIVO = 'CLEAR_MOTIVO';
