/* eslint-disable react/jsx-fragments */

import React, { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { READ, EMPRESA } from 'src/utils/rbac/tablePermissionsNames';
import ComponentsByRequiredPrivilegesJSX from 'src/utils/rbac/ComponentsByRequiredPrivilegesJSX';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, CircularProgress, TextField, Box } from '@material-ui/core';
import { listEmpresaInit } from '../redux/actions';
import { RootState } from '../redux/types';
import type { Theme } from '../theme';
import type { Emp } from '../@types/emp';
import { PAPEL3, PAPEL4, PAPEL5, PAPELDEFAULT } from 'src/utils/rbac/papeis';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

interface SetEmpProp {
  (emp: any): boolean;
}
interface AdminEmpSelectionProps {
  handleEmp: SetEmpProp;
}

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    fontSize: '0.8rem', // Tamanho da fonte das opções
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected
    }
  },
  input: {
    fontSize: '0.8rem'
  },
  topoNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '22px 10px 0px 10px'
  },
}));

const AdminEmpSelection: FC<AdminEmpSelectionProps> = ({ handleEmp }: AdminEmpSelectionProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { mongoUser, allEmps, myEmp, myPrivilege } = useSelector(
    (state: RootState) => ({
      mongoUser: state.account.user.mongoUser,
      isAdmin: state.account.user.isAdmin,
      allEmps: state.empresa.allEmps,
      loading: state.admin.loading,
      myEmp: state.account.myEmp,
      myPrivilege: String(state.account.user?.privilegeHash || PAPELDEFAULT)
    }),
    shallowEqual
  );

  const [empValue, setEmpValue] = useState<Emp | undefined>(myEmp);
  const [allEmpData, setAllEmpData] = useState<Emp[]>([]);
  const [open, setOpen] = React.useState(false);
  const loadingEmp = allEmpData.length === 0;

  // Função para filtrar apenas as empresas ativas
  const filterActiveEmps = (emps: any[]): any[] => {
    return emps.filter((emp) => emp.status === 'Ativa');
  };

  const fetchEmps = () => {
    const activeEmps = filterActiveEmps(allEmps);
    // Atualizar o estado com as empresas ativas
    setAllEmpData(activeEmps);
  };

  useEffect(() => {
    fetchEmps();
  }, [allEmps, myEmp]);

  const getEmps = useCallback(() => {
    if (!allEmps || allEmps.length === 0) {
      dispatch(listEmpresaInit({ mUser: mongoUser }));
    }
  }, []);

  useEffect(() => {
    getEmps();
  }, [getEmps]);

  const handleChange = (newValue: any | null) => {
    setEmpValue(newValue);
  };

  const handleSelectionEmp = (newValue: any): void => {
    handleEmp(newValue);
  };

  return (
    <>
      <ComponentsByRequiredPrivilegesJSX module={EMPRESA} privileges={[READ]}>
        <Box className={classes.topoNav}>
          <Autocomplete
            fullWidth
            id="empresa"
            options={allEmpData}
            loading={loadingEmp}
            loadingText="Aguarde carregando lista de empresas"
            getOptionLabel={(emp) => emp.identificador ? emp.identificador + " - " + emp.name : emp.name}
            value={empValue}
            disableClearable={true}
            size="small"
            onChange={(event, newValue) => {
              handleChange(newValue);
              handleSelectionEmp(newValue);
            }}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={(myPrivilege === PAPEL3  || myPrivilege === PAPEL4 || myPrivilege === PAPEL5) ? 'Selecionar Empresa' : ''}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  classes: { input: classes.option },
                  endAdornment: (
                    <React.Fragment>
                      {open && loadingEmp ? ( // Verifica se open é true e loadingEmp é true
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
            classes={{
              option: classes.option // Aplica os estilos personalizados às opções
            }}
          />
        </Box>
      </ComponentsByRequiredPrivilegesJSX>
    </>
  );
};

export default AdminEmpSelection;
